import { ForeignKey } from "@/models/fields.js";
import BaseModel from "@/models/base-model.js";
import EventModel from "@/models/event/event.js";

export default class EventExtra extends BaseModel {
    static describe () {
        return Object.assign({},
            BaseModel.describe(),
            {
                "event": {
                    type: ForeignKey(EventModel),
                    writable: true
                },
                "name": {
                    type: String,
                    writable: true
                },
                "price": {
                    type: Number,
                    writable: true
                },
                "description": {
                    type: String,
                    writable: true
                }
            }
        );
    }

    static get api_name () {
        return "event.eventextra";
    }
}

